.Heading{
  margin: 0 auto;
  margin-top: 30px;
  padding-top: 30px;
  /* color: white; */
  text-align: center;
  font-size: 36px;
}
.Heading a{
  text-decoration: none;
  color: white;
}

.serviceContainer{
width: 80%;
margin : 0 auto;
justify-content: center;

}

.services{
width: 100%;
margin: 0 auto;
margin-top: 55px;
}
.Heading h1{
  margin: 0;
}
.row{
grid-row-start: 2;
grid-row-end:3;
}
.row1{
grid-column-start:2;
grid-row-start: 2;
grid-row-end: 2;
}
.image-section {
  display: grid;
  /* margin: 20px 20px; */
  height: 950px;
  
  grid-template-columns: 1fr  1fr;
  grid-template-rows: repeat(2, 2fr);
  gap: 50px;
  margin-bottom: 100px;

  /* padding: 50px; */
}

.image-container {
  position: relative;
  overflow: hidden;
  height:100%;
  color: black;
  border-radius: 8px;
  /* max-height: 560px; */
}

.image-container img {
  width: 100%;
  height:100%;
  transition: transform 0.3s ease-out;
  opacity: 1;
}

.image-container:hover img {
  transform: scale(1.1);
}

.image-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 98%; */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0px 20px;
}

.image-content h3 {
  margin: 10px 0px;
  font-size: 24px;
  /* color: black; */
}

.image-content p {
  margin: 0;
  /* color: black;. */
  display: none;
  font-size: 18px;
  line-height: 1.2;

}
.image-container:hover .image-content{
  height: 100%;
  animation: content-animation 0.5s ease-in-out forwards;
 
}

.image-container:hover .image-content{
  height: 95%;
  animation: content-animation 0.5s ease-in-out forwards;
 
}
.image-content p span{
  color:#0a5c97;
  cursor: pointer;
}

.image-container:hover .image-content p{
  display: block;
  transform: translateY(1000px);
  animation: content-animation 0.5s ease-in-out forwards;
}

.row:hover .image-content .Service_hover,.row1:hover .image-content .Service_hover{
  position: absolute;
  bottom: 3%;
  animation: content-animation 0.5s ease-in-out forwards;
 
}


@media screen and (max-width: 450px) {
  .Heading{
    margin-top: 0px;
    font-size: 20px;
    color: white;
  }
  .services{
    margin-top: 30px;
  }
  section.image-section{
    height:1050px;
   
    grid-template-rows: 2fr 2fr 2fr 2fr;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }
    .row1{
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      }
    .mobile-grid{
       grid-column-start: 1;
       grid-column-end: 3;
    }
    .image-content{
      padding: 7px;
    }
    .image-content h3{
      margin: 0;
      font-size: 18px;
    }
    .image-content p{
      font-size: 14px;
        }
}

/* @media screen and (max-width: 820px) {
  
  .services{
    margin-top: 30px;
  }
  section.image-section{
    height:800px;
    grid-template-columns: 1fr  1fr;
    grid-template-rows: 0.8fr 0.8fr 1.8fr 1.8fr;
    gap: 10px;
    margin-bottom: 20px;
  }
    .row1{
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 3;
      }
    .mobile-grid{
       grid-column-start: 1;
       grid-column-end: 3;
    }
    
} */

