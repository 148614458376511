.container{
  width: 100%;
  background-color: black;
  height: 90px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.65);
  border-bottom: 3px solid rgb(14 122 185 / 96%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
}
.nav-item{
  width: 90%;
  height: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  display: flex;
  /* text-align: center; */
  
}
button.loginbutton{
  width:14%;
  margin: 0 10px;
}
button.loginbutton li{
  padding: 0;
}
button.loginbutton a{
  padding: 0;
}
header .logo-section{
  display: flex;
  width: 25%;
  height: 100%;
  justify-content: flex-start;
}
header .logo-section .logo{
  align-items: center;
  width: 50%;
  /* margin: 0 auto; */
  height:100%;
  max-width: 142px;
}
header .logo-section .logo1{
  align-items: center;
  width: 50%;
  /* margin: 0 auto; */
  height:100%;
  max-width: 150px;
}

header .logo-section .logo img{
  margin:0;
  position: absolute;
  max-width:128px;
  max-height: 140px;
}
header .logo-section .logo1 img{
  margin: 0 auto;
  margin: 20px 0px;
  width: 100%;
  position: absolute;
  max-width:240px;
  max-height: 140px;
}

nav ul.navlink{
  display: flex;
  /* margin-left: 50px; */
  /* padding: 0; */
}

.navlink li {
  list-style: none;
  /* margin: 0px 10px; */
  padding: 20px 0px;
}

nav ul li a {
  color: white;
  padding: 0px 20px;
  text-decoration: none;
  font-size: 19px;
  letter-spacing: 1.5px;
  font-weight: 500;

}

/* mobile device */

nav.mobile-device {
  /* background-color: #333; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  z-index: 1;
  display: none;
}

.nav-container {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
}
.dropdown {
  position: relative;
  display: inline-block;
  border: none;
}

.dropdown1 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  border-radius: 5px ;
}
.dropdown button{
  border: none;
}

.dropbtn {
  background-color: black;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding-right: 0;
  margin: 0.8rem 0;
  height: 2.5rem;
  padding: 0;
}

.dropdown-content {
  margin-top:0px;
  display: none;
  position: absolute;
  right: -27px;
  background-color: black;
  min-width: 358px;

  z-index: 1;
}
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: right;
}



.dark-mode{
  margin: 30px 0px;
  margin-right: 50px;
}

/* Hover effect */

.navlink li:hover > a{
color: #f98416
}

@media screen and (max-width: 450px) {
  .nav-item{
    width: 85%;
  }
  header .logo-section{
    width: 25%;
  }
}

@media screen and (max-width: 1431px) {
  .nav-item{
    width: 90%;
  }
}
@media screen and (max-width: 1345px) {
  header .logo-section .logo1 img{
    max-width:220px;
    margin-top: 25px;
  }
  header .logo-section .logo img{
    max-width:120px;
    margin-top: 5px;
    
  }
  nav ul li a {
    
    padding: 0px 10px;
    
    font-size: 15px;
    letter-spacing: 1.5px;

    
  }
}


@media screen and (max-width: 450px) {
  div.container{
    height: 67px;
    /*     border-bottom: 3px solid #6d6ff1; */
  }
 nav.desktop-device{
  display: none;
}
 nav.mobile-device{
   display: block;
  }
  .dropdown-content a {
    padding: 10px;
    text-align: center;
}

header .logo-section .logo img{
  margin-top:10px;
  max-width:65px;
  
}
header .logo-section .logo1 img{
  max-width:130px;
  margin-top: 20px;
}
header .logo-section{
  width: 42%;
}

.dropdown-content a:hover {
  color: #f98416
}
.dropdown{
  border: none;
}
.dropdown button:hover{
  border: none;

}
}


@media screen and (max-width: 820px) {

 nav.desktop-device{
  display: none;
}
 nav.mobile-device{
   display: block;
  }
  .dropdown-content a {
    padding: 10px;
    text-align: center;
}



header .logo-section{
  width: 42%;
}

.dropdown-content a:hover {
  color: #f98416
}
.dropdown{
  border: none;
}
.dropdown button:hover{
  border: none;

}
}
