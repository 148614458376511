.about{
  width: 100%;
}

.craouselContainer {
  width: 80%;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  padding: 2rem;
  border-radius: 10px;
  row-gap: 4rem;
  position: relative;
  overflow: hidden;
} 

.craouselContainer h1{
  /* color: white; */
  font-size: 50px;
}
.type1,
.type2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  z-index: 1;
}
.img {
  flex: 1;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
}
.img img {
  width: 100%;
  border-radius: 8px;
}
.content {
  /* color: white; */
  flex: 2;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.4rem;
}

@media screen and (max-width: 450px) {
  .craouselContainer{
    width:82%;
    margin-bottom: 10px;
  }
  .craouselContainer h1{
    /* color: white; */
    font-size: 28px;
    padding: 5px;
    display: block;

  }
  .content {
    flex: 1;
    /* color: white; */
    padding: 0px;
    border-radius: 8px;
    font-size: 10px;
    
  }
  .type1
{

  flex-direction: column;
  gap: 20px;

}
.type2{
  flex-direction: column-reverse;
  gap: 20px;
}
.content{
  flex: 0;
}
.content h2{
font-size: 20px;
line-height: 1.4rem;
}
.content p{
  font-size: 12px;
}
.content p a  {
  text-decoration: none;
  color:#0a5c97 ;
}
}

@media screen and (max-width: 820px) {
  .craouselContainer{
    width:80%;
    margin-bottom: 10px;
  }
  .craouselContainer h1{
 
    font-size: 28px;
    padding: 5px;
    display: block;

  }
  .content {
    flex: 1;
    
    padding: 0px;
    border-radius: 8px;
    font-size: 10px;
    
  }
  .type1
{

  flex-direction: column;
  gap: 30px;

}
.type2{
  flex-direction: column-reverse;
  gap: 30px;
}
.content{
  flex: 0;
}
.content h2{
font-size: 30px;
line-height: 1.4rem;
}
.content p{
  font-size: 12px;
}
.content p a  {
  text-decoration: none;
  color:#0a5c97 ;
}
}
