.container {
  color: white;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}
.heading {
  margin-bottom: 7rem;
  font-size: 48px;
  text-align: center;
}
.reasons {
  display: flex;
  justify-content: space-between;
  row-gap: 2rem;
}
.circles {
  height: 15vw;
  width: 15vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 25px solid rgb(14 122 185);
  box-shadow: 0 0 10px 0 rgb(14 122 185);
}
.circles:hover {
  border: 25px solid rgb(129, 194, 250);
  box-shadow: 0 0 10px 0 rgb(129, 194, 250);
  transition: box-shadow 0.5s linear;
}
 .contentWrapper h3{
  font-size: 48px;
    margin: 0px 16px 20px 16px;
    text-align: center;
 }
 .contentWrapper h5{
  font-size: 20px;
  margin: 0;
 }

 @media screen and (max-width: 820px){
  .circles {
    height: 17vw;
    width: 17vw;
    border: 18px solid rgb(14 122 185);

    
   
  }
  .contentWrapper h3{
    font-size: 24px;
      margin: 0px 16px 20px 16px;
      text-align: center;
   }
   .contentWrapper h5{
    font-size: 13px;
    margin: 0;
    text-align: center;
   }
 }