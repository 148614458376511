.aboutUs_Container{
    width: 100%;
    max-height: 800px;
    display: flex;
}

.aboutUs_Image{
    width: 100%;
    height: 100%;
    max-height: 800px;
}

.aboutUs_Container .aboutUs_Image img{
   width: 100%;
   max-height: 720px;
   opacity: 0.6;
}

.aboutUs_Container .aboutUs_Section1_Content{
    position: absolute;
    left: 500px;
    top: 185px;
    color: white;
    width: 44%;
    max-height: 500px;
    line-height: 3.5rem;
}

.aboutUs_Container .aboutUs_Section1_Content p{
    font-size: 40px;
    text-transform: capitalize;
    /* font-family: 'Tilt Prism', cursive; */
    font-family: 'Roboto Slab', serif;


    font-weight: 200;
    font-style: italic;
 }

 /* .aboutUs_Typing{
    animation: p_animation 3s ease-out;


 } */

 @keyframes p_animation {
    0% {
    /* display: block; */
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
 @media screen and (max-width:450px) {

    .aboutUs_Container .aboutUs_Section1_Content{
        position: absolute;
        left: 12%;
        top: 10%;
        color: white;
        width: 80%;
       
        line-height: 1.4rem;
    }
    .aboutUs_Container .aboutUs_Section1_Content p{
        font-size: 18px;
        text-align: center;
        
     }
  
    .aboutUs_Container .aboutUs_Image img{
       height: 320px;
    }
    
 }
 @media screen and (max-width:820px) {

    .aboutUs_Container .aboutUs_Section1_Content{
        position: absolute;
        left: 12%;
        top: 13%;
        color: white;
        width: 80%;
       
        line-height: 1.6rem;
    }
    .aboutUs_Container .aboutUs_Section1_Content p{
        font-size: 24px;
        text-align: center;
        
     }
  
    .aboutUs_Container .aboutUs_Image img{
       height: 400px;
    }
    
 }