.job_description{
    margin: 30px;
    display: block;
}
.job_description_content ul li{
    padding: 7px;
    line-height: 1.8rem;
    letter-spacing: 1.2px;
    font-size: 20px;
    color: white;
}

@media screen and (max-width:450px) {
.job_description{
    margin-top: 0;
    width: 95%;
    margin: 0 auto;
}
.job_description_title h1{
    margin-top: 0;
    font-size: 20px
    
}
.job_description_content ul{
    padding-left: 25px;
}
.job_description_content ul li{
    font-size: 16px;
    line-height: 1.3rem;
    padding:4px ;
}
}
