.mobileabout{
  color: white;
}
.heading {
  margin-bottom: 7rem;
  font-size: 48px;
  text-align: center;
}
.reasons {
  display: flex;
  justify-content: space-between;
  row-gap: 2rem;
}
.circles {
  height: 15vw;
  width: 15vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 25px solid rgb(14 122 185);
  box-shadow: 0 0 10px 0 rgb(14 122 185);
}
.circles:hover {
  border: 25px solid rgb(129, 194, 250);
  box-shadow: 0 0 10px 0 rgb(129, 194, 250);
  transition: box-shadow 0.5s linear;
}
.circleWrapper1,.circleWrapper2,.circleWrapper3,.circleWrapper4{
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  left: -10vw;
  position: relative;
  margin-bottom: 1rem;
  width : 70%
}
.circleWrapper1{
  animation: circlePush1 8s infinite;
}

.circleWrapper2{
  animation: circlePush3 8s infinite;
}

 .circleWrapper3{
  animation: circlePush1 8s infinite;
}

 .circleWrapper4{  
  animation: circlePush3 8s infinite;
 }  



.contentWrapper{
  flex: 1;
}

/* for 1st circle*/
@keyframes circlePush1{
  0%{
    left: 0vw;
  }
  12.5%{
    left: 5vw;
  }
  25%{
    left: 0vw;
  }
  37.5%{
    left: 0vw;
  }
  50%{
    left: 0vw;
  }
  62.5%{
    left: 0vw;
  }
  75%{
    left: 0vw;
  }
  88.5%{
    left:0vw;
  }
  100%{
    left:0vw;
  }
}
@keyframes cirlePush2{
  0%{
    left: -10vw;
  }
  12.5%{
    left:-10vw;
  }
  25%{
    left:-10vw;
  }
  37.5%{
    left: 0vw;
  }
  50%{
    left: -10vw;
  }
  62.5%{
    left: -10vw;
  }
  75%{
    left: -10vw;
  }
  88.5%{
    left:-10vw;
  }
  100%{
    left:-10vw;
  }
}

@keyframes circlePush3{
  0%{
    left: 0vw;
  }
  12.5%{
    left: 0vw;
  }
  25%{
    left: 0vw;
  }
  37.5%{
    left: 0vw;
  }
  50%{
    left: 0vw;
  }
  62.5%{
    left: 5vw;
  }
  75%{
    left: 0vw;
  }
  88.5%{
    left:0vw;
  }
  100%{
    left:0vw;
  }
}

@keyframes circlePush4{
  0%{
    left: -10vw;
  }
  12.5%{
    left:-10vw;
  }
  25%{
    left:-10vw;
  }
  37.5%{
    left: -10vw;
  }
  50%{
    left: -10vw;
  }
  62.5%{
    left: -10vw;
  }
  75%{
    left: -10vw;
  }
  88.5%{
    left: 0vw;
  }
  100%{
    left:-10vw;
  }
}