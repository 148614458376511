.Service_Container{
    width: 100%;
    max-height: 800px;
    display: flex;
}

.Service_Image{
    width: 100%;
    height: 100%;
    max-height: 900px;
}

.Service_Container .Service_Image img{
   width: 100%;
   max-height: 800px;
   height: 700px;
   opacity: 1;
}

.service_provide{
    width: 100%;
    height: 100%;
    margin-top: 100px;
    padding: 20px 0px;
}
.content{
    width: 45%;
    position: absolute;
    top: 33%;
    left: 2%;
    color: rgb(226, 222, 222);
}
.content h1{
    font-weight: 200;
    /* animation: p_animation 3s ease-out; */

}
.title{
    width: 100%;
 
}

.service_title{
    width: 100%;
    color: white;
    font-size: 24px;
    text-align: left;
    
    
}
.service_title h1{
    margin-top: 0;
    margin-bottom: 10px;
}
.service_box{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-bottom: 100px;
}

.service_box_image{
    width:50%;
    height: 100%;
    padding-top: 10px;

}
.service_box_image img{
    width: 90%;
    height: 500px;
    margin: 8px 38px;
}

.service_box_content{
    width: 50%;
}
.service_content{
    color: white;

}
.service_content p{
    font-size: 20px;
    line-height: 1.4rem;
    letter-spacing: 1.2px;
    margin-top: 0;
    font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
}
.service_content ul{
    margin: 0px 35px;
}
.service_content ul li{
    font-size: 20px;
    /* line-height: 2rem; */
    letter-spacing: 1px;
    margin: 10px;
    font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@keyframes p_animation {
    0% {
    /* display: block; */
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@media (max-width: 450px) {
    .content {
      font-size: 0.4rem;
      text-align: center;
      top: 11%;
      left: 5%;

      
    }

  
    .Service_Container .Service_Image img {
      max-height: 800px;
      height: 270px;
    }
  }

/* @media (max-width: 820px){
    .content {
      
        
        top: 15%;
        left: 5%;
  
        
      }
  

} */