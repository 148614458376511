.container {
  margin: 100px auto;
  height: 100%;
  position: relative;
}
.wrapper {
  width: 80%;
  margin: auto;
  position: relative;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 5%;
}
.wrapper1 {
  width: 80%;
  margin: auto;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 5%;
}
.serviceCard {
  flex: 1;
  border: 2px solid white;
  border-radius: 8px;
  text-align: center;
  color: white;
  padding: 1rem;
}
.serviceCard h1 {
  margin: 0;
}
.plus {
  align-items: center;
  justify-content: center;
  width: auto;
  display: flex;
  transition: transform 0.5s linear;
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
}
.im{
  width: 90%;
  margin: 0 auto;
  height: 100%;
}
.im img{
  height: 200px;
  width: 100%;

}

.serviceCard :hover ~ .plus {
  transform: rotate(180deg) !important;
}
.popup {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: black;
  color: white;
  z-index: 10;
  padding: 1rem;
  border-radius: 8px;
}
.header {
  display: flex;
}
.header h1 {
  flex: 1;
  font-size: 40px;
}
.header img {
  transform: rotatez(45deg);
}
.image img {
  width: 100%;
  height: 100%;
}
.content {
  display: flex;
  flex-direction: row;

}
.image{
  width: 40%;
}
.paragraph{
  width: 60%;

}
.paragraph p{
  font-size: 22px;
}
@media (max-width: 450px) {
  .container{
    margin: 30px auto;
  }
  .wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 5%;
    gap: 20px;
  }
  .serviceCard {
    width: 80%;
    font-size: 0.6rem;
  }
  .serviceCard h1 {
    font-size: 24px;
    margin: 0;
  }
  .header h1 {
    font-size: 30px;
    margin-top: 0;
  }
  .paragraph p{
    font-size: 20px;
  }
  .content {
    flex-direction: column-reverse;
  }
  .popup {
    left: -5%;
    width: 100%;
  }
  .image {
    width: 100%;
  }
  .paragraph {
    width: 100%;
  }
  .paragraph p {
    font-size: 18px;
  }
  .im img{
    height: 150px;
  
  
  }
}

/* @media (max-width: 820px) {
  .container{
    margin: 30px auto;
  }
  .wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 5%;
    gap: 20px;
  }
  .serviceCard {
    width: 80%;
    font-size: 0.6rem;
  }
  .serviceCard h1 {
    font-size: 36px;
    margin: 0;
  }
  .header h1 {
    font-size: 30px;
    margin-top: 0;
  }
  .paragraph p{
    font-size: 20px;
  }
  .content {
    flex-direction: column-reverse;
  }
  .popup {
    left: -5%;
    width: 100%;
  }
  .image {
    width: 100%;
  }
  .paragraph {
    width: 100%;
  }
  .paragraph p {
    font-size: 18px;
  }
  .im img{
    height: 300px;
  
  
  }
} */
