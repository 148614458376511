

.Card_container{
    width: 100%;
    border: 1px groove white;
    border-radius: 12px;
    transition: transform .3s ease-out,background-color .3s,box-shadow .4s,-webkit-transform .3s ease-out;
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.05);

}

.Card_title{
    
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.Card_button{
    width:23%;
    background-color: #0a5c97;

}
.Card_title h1{
    font-size: 40px;
    color: white;
    text-align: left;
    /* margin: 1.8rem 0; */
}

.Card_heading{
    padding: 20px;
    padding-top: 0px;
}
.Card_heading h3{
    margin-top: 0;
    font-size: 24px;
    /* margin-bottom: 30px; */
}
.Card_heading ul{
    margin-bottom: 30px;
}
.Card_heading ul li{
    font-size: 20px;
}
.Card_heading ul li a{
    color: #0a5c97;
    text-decoration: none;
}

.Card_heading ul li a:hover{
    color: #0a5c97;
    text-decoration: underline;
}

.Card_container:hover{
    transform: scale(1.1);
}

@media screen and (max-width:450px) {
    .Card_title{
        padding: 15px;
        padding-bottom: 0;
    }
    .Card_title h1{
        font-size: 26px;
    }
    .Card_button{
        width: 26%;
        padding: 0;
    }
    .Card_heading{
        padding: 15px;
        padding-top: 0;
    }
    .Card_heading h3{
        font-size: 19px;
    }
    .Card_heading ul li{
        font-size: 16px;
    }
}
